body::-webkit-scrollbar {
  width: 0.5em;
}

body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

body::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}

.p-growl {
  z-index: 9999 !important;
}

zxing-scanner {
  /* Root component needs to have height: 100% */
  height: 100%;
  /* Optional : you probably want full-width as well for landscape orientation or tablets */
  width: 100%;
}

.ncs-dialog {
  max-width: 100%;
  overflow: auto;
  max-height: 100%;
}

.p-dropdown {
  max-width: 100%;
  min-width: 0;
}

// CAUTION: When not dropdown content
.email-form-dialog .ncs-dialog.p-dialog .p-dialog-content {
  overflow: auto;
}

.storage-rack .p-fieldset .p-fieldset-legend {
  color: white;
  text-align: center !important;
  width: 100%;
  background: #124325;
}

.p-listbox{
  width: 100%;
}